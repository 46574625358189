/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ViewType } from '~/types'

export const urls = {
    default: () => '/',
    notFound: () => '404',
    dashboards: () => '/dashboard',
    dashboard: (id: string | number) => `/dashboard/${id}`,
    createAction: () => `/action`, // TODO: For consistency, this should be `/action/new`
    action: (id: string | number) => `/action/${id}`,
    actions: () => '/actions',
    insights: () => '/insights',
    insightView: (view: ViewType) => `/insights?insight=${view}`,
    insightRouter: (id: string) => `/i/${id}`,
    savedInsights: () => '/saved_insights',
    events: () => '/events',
    sessions: () => '/sessions',
    sessionRecordings: () => '/recordings',
    person: (id: string) => `/person/${id}`,
    persons: () => '/persons',
    cohort: (id: string | number) => `/cohorts/${id}`,
    cohorts: () => '/cohorts',
    featureFlags: () => '/feature_flags',
    featureFlag: (id: string | number) => `/feature_flags/${id}`,
    annotations: () => '/annotations',
    plugins: () => '/project/plugins',
    projectCreateFirst: () => '/project/create',
    projectSettings: () => '/project/settings',
    mySettings: () => '/me/settings',
    organizationSettings: () => '/organization/settings',
    organizationBilling: () => '/organization/billing',
    organizationCreateFirst: () => '/organization/create',
    instanceLicenses: () => '/instance/licenses',
    systemStatus: () => '/instance/status',
    systemStatusPage: (page: string) => `/instance/status/${page}`,
    // Onboarding / setup routes
    login: () => '/login',
    preflight: () => '/preflight',
    signup: () => '/signup',
    inviteSignup: (id: string) => `/signup/${id}`,
    personalization: () => '/personalization',
    ingestion: () => '/ingestion',
    onboardingSetup: () => '/setup',
    home: () => '/home',
}
